import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { newCustomer } from "../../helper/initialData";
import {
  usePostCustomerMutation,
  useUpdateCustomerMutation,
} from "../../services/customers";
import DeleteIcon from "@mui/icons-material/Delete";

const CustomerRegistrationModal = ({
  isVisible,
  toggleModal,
  initialState,
}) => {
  const [updateCustomer] = useUpdateCustomerMutation();
  const [postCustomer] = usePostCustomerMutation();
  const [formData, setFormData] = useState(initialState || newCustomer);
  const [errors, setErrors] = useState({
    adSoyad: "",
    tckn: "",
    telefon: "",
    email: "",
    vdKodu: "",
    ticSicilNo: "",
    parasutClientId: "",
    parasutClientSecret: "",
    parasutEmail: "",
    access_token: "",
    refresh_token: "",
    parasutUsername: "",
    parasutPassword: "",
  });
  const [modalMessage, setModalMessage] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [shareholders, setShareholders] = useState(
    initialState?.hissedarlar || []
  );
  const [shareholderCount, setShareholderCount] = useState("");
  const addShareholder = () => {
    setShareholders(
      Array(shareholderCount)
        .fill()
        .map(() => ({ adi_soyadi: "", hisse_orani: "" }))
    );
  };
  const deleteShareholder = (index) => {
    const updatedShareholders = shareholders.filter((_, i) => i !== index);
    setShareholders(updatedShareholders);
  };
  const handleShareholderCountChange = (event) => {
    const count = parseInt(event.target.value, 10);
    setShareholderCount(count);
  };

  const handleShareholderChange = (index, field, value) => {
    const updatedShareholders = [...shareholders];
    updatedShareholders[index][field] = value;
    setShareholders(updatedShareholders);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.id) {
      updateCustomer({ ...formData, hissedarlar: shareholders });
    } else {
      postCustomer({ ...formData, hissedarlar: shareholders });
    }
    toggleModal();
  };

  return (
    <Dialog open={isVisible}>
      <DialogTitle>
        {formData.id ? "Mükellef Güncelle" : "Yeni Mükellef Kaydı"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexWrap: "wrap",
              flex: "1 1 100%",
              width: "100%",
            }}
          >
            <TextField
              label="Ad Soyad/Ünvan"
              name="adSoyad"
              value={formData.ad_soyad || formData.adSoyad}
              onChange={handleChange}
              error={!!errors.adSoyad}
              helperText={
                errors.adSoyad ||
                "Ad Soyad/Ünvan bilgisi eksiksiz yazılmalıdır. Eğer burayı eksik girerseniz belgelerde eksik veri oluşması muhtemeldir."
              }
              margin="dense"
              required
            />
            <TextField
              sx={{ flexGrow: 1 }}
              label="Telefon Numarası"
              name="telefon"
              value={formData.telefon}
              onChange={handleChange}
              error={!!errors.telefon}
              helperText={errors.telefon}
              margin="dense"
            />
            <TextField
              sx={{ flexGrow: 1 }}
              label="E-mail Adresi"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              margin="dense"
            />
            <TextField
              sx={{ flexGrow: 1 }}
              label="TCKN/VKN"
              name="tckn"
              value={formData.tckn}
              onChange={handleChange}
              error={!!errors.tckn}
              helperText={errors.tckn}
              margin="dense"
              required
            />

            <TextField
              sx={{ flexGrow: 1 }}
              label="Vergi Dairesi Kodu"
              name="vdKodu"
              value={formData.vdKodu}
              onChange={handleChange}
              error={!!errors.vdKodu}
              helperText={errors.vdKodu}
              margin="dense"
              required
            />
            {/* ... diğer alanlar ... */}
            <TextField
              sx={{ flexGrow: 1 }}
              label="Ticaret Sicil Numarası"
              name="ticSicilNo"
              value={formData.ticSicilNo}
              onChange={handleChange}
              error={!!errors.ticSicilNo}
              helperText={errors.ticSicilNo}
              margin="dense"
              required
            />


            <Box sx={{ display: "flex", gap: "0.5rem", width: "100%" }}>
              <TextField
                sx={{ flex: 1 }}
                label="Sözleşme Tarihi"
                name="sozlesmeTarih"
                value={formData.sozlesmeTarih}
                onChange={(e) => {
                  const selectedDate = e.target.value;
                  const formattedDate = new Date(selectedDate)
                    .toISOString()
                    .split("T")[0];
                  setFormData((prevData) => ({
                    ...prevData,
                    sozlesmeTarih: formattedDate,
                  }));
                }}
                error={!!errors.sozlesmeTarih}
                helperText={errors.sozlesmeTarih || ""}
                margin="dense"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
              <TextField
                sx={{ flex: 1 }}
                label="Sözleşme Numarası"
                name="sozlesmeNo"
                value={formData.sozlesmeNo}
                onChange={handleChange}
                error={!!errors.sozlesmeNo}
                helperText={errors.sozlesmeNo}
                margin="dense"
                required
              />
            </Box>

            <Divider />
            <Typography variant="body2" color="secondary.second">
              Mükellefinize ait E-Arşiv Portal giriş bilgileri varsa aşağıdaki
              alanları eksiksiz doldurunuz yoksa boş bırakabilirsiniz!
            </Typography>
            <TextField
              sx={{ flexGrow: 1 }}
              label="E-Arşiv Portal Kimlik No"
              name="eArsivPortalKimlikNo"
              value={
                formData.e_arsiv_portal_kimlik_no ||
                formData.eArsivPortalKimlikNo
              }
              onChange={handleChange}
              error={!!errors.eArsivPortalKimlikNo}
              helperText={errors.eArsivPortalKimlikNo}
              margin="dense"
            />
            <TextField
              sx={{ flexGrow: 1 }}
              label="E-Arşiv Portal Şifre"
              name="eArsivPortalPassword"
              value={
                formData.e_arsiv_portal_password ||
                formData.eArsivPortalPassword
              }
              onChange={handleChange}
              error={!!errors.eArsivPortalPassword}
              helperText={errors.eArsivPortalPassword}
              margin="dense"
            />

            <Divider />
            <Typography
              variant="body2"
              color="secondary.second"
              sx={{ width: "100%", mb: 1 }}
            >
              Mükellefinize ait hissedar/hissedarlar varsa eklemek istediğiniz
              hissedar sayısını girip ardından butona tıklayarak
              ekleyebilirsiniz yoksa boş bırakabilirsiniz:
            </Typography>

            <TextField
              label="Hissedar Sayısı"
              type="number"
              value={shareholderCount}
              onChange={handleShareholderCountChange}
              inputProps={{ min: 0 }}
              sx={{ mb: 2 }}
            />
            <Button
              onClick={addShareholder}
              variant="contained"
              color="btnActiveColor"
              sx={{ mb: 2, textTransform: "none" }}
            >
              Hissedar Ekle
            </Button>
            {shareholders.map((shareholder, index) => (
              <>
                <Box key={index}>
                  <Box sx={{ display: "flex", gap: 2, width: "100%", mb: 2 }}>
                    <TextField
                      label="Adı Soyadı"
                      value={shareholder.adi_soyadi}
                      onChange={(e) =>
                        handleShareholderChange(
                          index,
                          "adi_soyadi",
                          e.target.value
                        )
                      }
                      fullWidth
                    />
                    <TextField
                      label="Hisse Oranı"
                      type="number"
                      value={shareholder.hisse_orani}
                      onChange={(e) =>
                        handleShareholderChange(
                          index,
                          "hisse_orani",
                          e.target.value
                        )
                      }
                      fullWidth
                      InputProps={{
                        inputProps: { min: 0, max: 100, step: 0.01 },
                      }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: 2, width: "100%", mb: 2 }}>
                    <TextField
                      label="TCKN"
                      value={shareholder.tckn}
                      onChange={(e) =>
                        handleShareholderChange(index, "tckn", e.target.value)
                      }
                      fullWidth
                    />
                    <TextField
                      label="İkametgah Adresi"
                      type="text"
                      value={shareholder.ikametgahAdresi}
                      onChange={(e) =>
                        handleShareholderChange(
                          index,
                          "ikametgahAdresi}",
                          e.target.value
                        )
                      }
                      fullWidth
                      InputProps={{
                        inputProps: { min: 0, max: 100, step: 0.01 },
                      }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      onClick={() => deleteShareholder(index)}
                      color="btnActiveColor"
                      variant="contained"
                    >
                      KALDIR
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ width: "100%", mb: 1 }}>
                  <Divider sx={{ bgcolor: "black" }} />
                </Box>
              </>
            ))}
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="btnActiveColor"
              sx={{ color: "white" }}
            >
              {formData.id ? "Güncelle" : "Kaydet"}
            </Button>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal} color="btnColor">
          İptal
        </Button>
      </DialogActions>
      <Dialog
        open={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
      >
        <DialogTitle>Uyarı</DialogTitle>
        <DialogContent>{modalMessage}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccessModalVisible(false);
              toggleModal();
            }}
            color="primary"
          >
            Tamam
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CustomerRegistrationModal;
