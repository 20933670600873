import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { notify } from "../helper/notify"; // notify fonksiyonunuzun doğru yolunu kontrol edin
import { logoutSuccess } from "../features/authSlice"; // logout action'ınızı import edin
import { useNavigate } from "react-router-dom";

// Auth gerektirmeyen istekler için public axios örneği:
export const axiosWithPublic = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Token içeren axios örneğini döndüren custom hook:
const useAxios = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const axiosWithToken = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 20 * 60 * 1000, // 20 dakika
    headers: { Authorization: `Token ${token}` },
  });

  // Response interceptor: 401 hatası alınırsa, notify ile uyarı gösterip logout işlemini tetikler.
  axiosWithToken.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        const errorMessage =
          error.response.data.detail ||
          "Hesabınız başka bir cihazdan oturum açtığı için mevcut oturumunuz güvenlik gereği sonlandırılmıştır.";
        
        // notify fonksiyonu SweetAlert2 ile çalışıyor ve bir promise döndürüyor.
        notify(errorMessage, "error").then(() => {
          // Uyarı kapatıldıktan sonra logout işlemini gerçekleştiriyoruz.
          dispatch(logoutSuccess());
          navigate("/"); // Ana sayfaya veya login sayfasına yönlendirin.
        });
      }
      return Promise.reject(error);
    }
  );

  return axiosWithToken;
};

export default useAxios;
