// src/components/IndirimliOranListesi.js

import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Grid,
} from "@mui/material";

// Ortak bileşenler, helper, custom hooks
import MyButton from "../Commons/MyButton";
import useAxios from "../../hooks/useAxios";
import TableComponent from "../Tables/TableComponent";
import {
  yuklenilecekKDVColumns, // 7. adımda tablo
  kdvLoadColumns, // 9. adım tablo
  kdvReportColumns, // 10. adım tablo
  // totalKdvPerPeriodColumns vs. başka kolonlar da olabilir
} from "../../helper/columns";
import ExportData from "../Commons/ExportData";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";
import { newCustomer } from "../../helper/initialData";

// Form ve diğer bileşenler
import IndirimliKayitForm from "../Forms/IndirimliKayitForm";
import EnvanterKaydi from "../Islemler/EnvanterKaydi";
import KDVBeyannamesiKaydi from "../Islemler/KDVBeyannamesiKaydi";

// RTK Query lazy import (12. adım)
import { useLazyKarsitIncelemeSummaryYuklenilecekQuery } from "../../services/indirimliOran";
import { notify } from "../../helper/notify";

/**
 * Props:
 *  - tckn: Mükellefin TCKN/VKN değeri
 *  - selectedProject: (opsiyonel) Seçili proje objesi
 *  - mode: (opsiyonel) "readonly" ise sadece GET odaklı adımları (7,9,10,11,12) gösterilir
 */
const IndirimliOranListesi = ({ tckn, selectedProject, mode }) => {
  // Adım yönetimi (Stepper)
  const [activeStep, setActiveStep] = useState(0);

  // 7. Adım (Yüklenim Listesi) state’leri
  const [yuklenecekKDVData, setYuklenecekKDVData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fallbackUsed, setFallbackUsed] = useState(false); // Proje 1 fallback

  // Diğer adımlar (9, 10 vs.) için state
  const [kdvLoadData, setKdvLoadData] = useState(null);
  const [loadingKdvLoad, setLoadingKdvLoad] = useState(false);
  const [errorKdvLoad, setErrorKdvLoad] = useState("");

  const [iadeKdvData, setIadeKdvData] = useState([]);
  const [loadingIadeKdv, setLoadingIadeKdv] = useState(false);
  const [errorIadeKdv, setErrorIadeKdv] = useState("");

  // 12. adım: RTK Query Lazy
  const [
    triggerKarsitYuklenilecekSummary,
    { data: karsitYuklenilecekData, isLoading: karsitYuklenilecekLoading },
  ] = useLazyKarsitIncelemeSummaryYuklenilecekQuery();

  // Pivot verisi + dinamik kolonlar (12. adım)
  const pivotDataWithNames = useMemo(() => {
    if (!karsitYuklenilecekData || !karsitYuklenilecekData.data) return [];
    const rows = karsitYuklenilecekData.data;
    const namesDict = karsitYuklenilecekData.supplier_names || {};
    return rows.map((row) => {
      const newRow = { ...row };
      const tcknVal = row["Satıcı TCKN/VKN"];
      if (namesDict[tcknVal]) {
        newRow["Satıcı TCKN/VKN"] = namesDict[tcknVal];
      }
      return newRow;
    });
  }, [karsitYuklenilecekData]);

  const dynamicYuklenilecekColumns = useMemo(() => {
    if (!pivotDataWithNames.length) return [];
    // İlk sabit kolon
    const baseColumns = [{ Header: "Satıcı Adı", accessor: "Satıcı TCKN/VKN" }];
    // Kalan key’leri dinamik bul
    const uniqueMonths = new Set();
    pivotDataWithNames.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "Satıcı TCKN/VKN") {
          uniqueMonths.add(key);
        }
      });
    });
    // Sıralama
    const sortedKeys = Array.from(uniqueMonths).sort((a, b) =>
      a.localeCompare(b)
    );
    const dynamicCols = sortedKeys.map((month) => ({
      Header: month,
      accessor: month,
    }));
    return [...baseColumns, ...dynamicCols];
  }, [pivotDataWithNames]);

  // axios
  const axiosWithToken = useAxios();

  // Form datası (Proje vs.)
  const [formData, setFormData] = useState(() => ({
    id: null,
    name: "",
    registration_no: "",
    registration_date: "",
    building_no: "",
    total_floors: "",
    sections: {
      mesken: { count: "", area: "" },
      ortak: { count: "", area: "" },
    },
    project_id: "",
    building_id: "",
    housing_details: [],
    vkn_no: "",
  }));
  console.log("formData", formData);
  // Proje ID (mode === "readonly" + selectedProject => selectedProject.id)
  const projectId = useMemo(() => {
    if (mode === "readonly" && selectedProject) {
      return selectedProject.id;
    }
    return formData?.project_id || 1;
  }, [mode, selectedProject, formData]);
  console.log("projectId", projectId);
  // Modal (form) yönetimi
  const [open, setOpen] = useState(false);
  const [formSection, setFormSection] = useState(null);
  const handleOpen = (section) => {
    setFormSection(section);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [step6Completed, setStep6Completed] = useState(false);
  // ----------------------------------------------------------------
  // 7. ADIM: Yüklenim Listesi verisi çekme (manuel axios)
  // ----------------------------------------------------------------
  const getGroupedInvoiceData = async (pId) => {
    if (!pId) return;
    try {
      setLoading(true);
      setError("");
      setFallbackUsed(false);

      const endpoint = `api/indirimli_oran/fetch_grouped_invoice_with_details/${pId}/`;
      console.log("Fetching grouped invoice data from:", endpoint);
      const response = await axiosWithToken.get(endpoint);

      if (
        response.data.error ||
        (response.data.data && response.data.data.length === 0)
      ) {
        const errMsg =
          response.data.error || "Bu proje için kayıtlı grup bulunamadı.";
        console.log("Backend error:", errMsg, "Fallback to project id 1");
        setError(errMsg);
        setFallbackUsed(true);

        const fallbackEndpoint = `api/indirimli_oran/fetch_grouped_invoice_with_details/1/`;
        console.log("Fetching grouped invoice data from:", fallbackEndpoint);
        const fallbackResponse = await axiosWithToken.get(fallbackEndpoint);

        if (
          fallbackResponse.data.error ||
          (fallbackResponse.data.data &&
            fallbackResponse.data.data.length === 0)
        ) {
          setError(
            fallbackResponse.data.error ||
              "Proje 1 için kayıtlı grup bulunamadı."
          );
          setYuklenecekKDVData([]);
        } else {
          setYuklenecekKDVData(fallbackResponse.data.data || []);
        }
      } else {
        setYuklenecekKDVData(response.data.data || []);
      }
      setLoading(false);
    } catch (err) {
      const errMsg =
        err.response?.data?.message ||
        err.message ||
        "Yüklenim listesi çekilirken bir hata oluştu";
      console.error("Error fetching grouped invoice data:", errMsg);
      setError(errMsg);
      setLoading(false);
    }
  };

  // ----------------------------------------------------------------
  // 9. ADIM: Metrekare Oranı Ve Yüklenim Payı (manuel axios)
  // ----------------------------------------------------------------
  const getKdvLoadData = async (pId) => {
    if (!pId) return;
    try {
      setLoadingKdvLoad(true);
      setErrorKdvLoad("");
      const endpoint = `api/indirimli_oran/calculate_kdv_load/${pId}/`;
      const response = await axiosWithToken.get(endpoint);
      console.log("KDV yükü hesaplandı:", response.data);
      setKdvLoadData(response.data);
    } catch (err) {
      setErrorKdvLoad(err.message || "KDV yükü hesaplanırken hata oluştu.");
    } finally {
      setLoadingKdvLoad(false);
    }
  };

  // ----------------------------------------------------------------
  // 10. ADIM: İndirimli Oran İade edilecek Vergi Tutarı (manuel axios)
  // ----------------------------------------------------------------
  const getIadeKdvData = async (pId) => {
    if (!pId) return;
    try {
      setLoadingIadeKdv(true);
      setErrorIadeKdv("");
      const endpoint = `api/indirimli_oran/generate_kdv_report/?format=json&project_id=${pId}&tckn=${
        tckn || formData.vkn_no || "9520578377"
      }`;
      const response = await axiosWithToken.get(endpoint);
      console.log("İade KDV hesaplandı:", response.data);
      setIadeKdvData(response.data || []);
    } catch (err) {
      setErrorIadeKdv(err.message || "İade KDV verisi alınırken hata oluştu.");
    } finally {
      setLoadingIadeKdv(false);
    }
  };

  // ----------------------------------------------------------------
  // 12. ADIM: Karşıt İnceleme Yüklenilecek Özet (RTK Query Lazy)
  // ----------------------------------------------------------------
  // Zaten yukarıda triggerKarsitYuklenilecekSummary, karsitYuklenilecekData tanımlandı.

  // Stepper + effect => Adım aktif olduğunda verileri çek
  useEffect(() => {
    // 7. Adım (Yüklenim Listesi)
    const yüklenimStepIndex = stepsToRender.findIndex(
      (step) => step.label === "Yüklenim Listesi"
    );
    if (activeStep === yüklenimStepIndex && projectId) {
      getGroupedInvoiceData(projectId);
    }
  }, [activeStep, projectId]);

  useEffect(() => {
    // 9. Adım (Metrekare Oranı ve Yüklenim Payı)
    const metrekareStepIndex = stepsToRender.findIndex(
      (step) => step.label === "Metrekare Oranı Ve Yüklenim Payı"
    );
    if (activeStep === metrekareStepIndex && projectId) {
      getKdvLoadData(projectId);
    }
  }, [activeStep, projectId]);

  useEffect(() => {
    // 10. Adım (İndirimli Oran İade)
    const iadeStepIndex = stepsToRender.findIndex(
      (step) =>
        step.label === "İndirimli Oran İade edilecek Vergi Tutarı hesap tablosu"
    );
    if (activeStep === iadeStepIndex && projectId) {
      getIadeKdvData(projectId);
    }
  }, [activeStep, projectId]);

  useEffect(() => {
    // 12. Adım (Karşıt İnceleme Yüklenilecek Özet)
    const yuklenilecekStepIndex = stepsToRender.findIndex(
      (step) => step.label === "Karşıt İnceleme Yüklenilecek Özet"
    );
    if (activeStep === yuklenilecekStepIndex && projectId) {
      // Otomatik tetiklemek istersek:
      const vknNoParam = formData.vkn_no || "9520578377";
      triggerKarsitYuklenilecekSummary({
        projectId,
        vknNo: vknNoParam,
      });
    }
  }, [activeStep, projectId]);

  // tckn değişince step sıfırlama
  useEffect(() => {
    setYuklenecekKDVData([]);
    setActiveStep(0);
    setError("");
  }, [tckn]);

  // Tüm adımlar
  const allSteps = [
    {
      label: "Proje Ait Bilgileri Yükle",
      description:
        "Proje kaydı oluşturup devam etmek için bu adımı tamamlayın.",
      content: (
        <Grid item xs="auto" md="auto">
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={() => handleOpen(1)}
            title="YENİ PROJE KAYDI OLUŞTUR"
            size="small"
            sx={{ width: "auto", minWidth: "120px", padding: "6px 16px" }}
          />
        </Grid>
      ),
    },
    {
      label: "Fatura Kaydı Yükle",
      description: "Fatura kaydı yüklemek için bu adımı tamamlayın.",
      content: <EnvanterKaydi tckn={tckn} />,
    },
    {
      label: "KDV Beyannamesi Kaydı Yükle",
      description:
        "KDV Beyannamesi kaydı yüklemek için gerekli bilgileri sağlayın.",
      content: <KDVBeyannamesiKaydi tckn={tckn} getIadeyeEsasKDV={() => {}} />,
    },
    {
      label: "İşçi İstatistikleri",
      description: "İşçi sayısı ve gider bilgilerini gireceğiniz adım.",
      content: (
        <Grid item xs="auto" md="auto">
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={() => handleOpen(4)}
            title="İŞÇİ İSTATİSTİK KAYDI OLUŞTUR"
            size="small"
            sx={{ width: "auto", minWidth: "120px", padding: "6px 16px" }}
          />
        </Grid>
      ),
    },
    {
      label: "Yapı Detay Tablosu",
      description:
        "Bina, mesken/ortak alan ve daire bilgilerini gireceğiniz adım.",
      content: (
        <Grid item xs="auto" md="auto">
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={() => handleOpen(5)}
            title="YAPI DETAY KAYDI OLUŞTUR"
            size="small"
            sx={{ width: "auto", minWidth: "120px", padding: "6px 16px" }}
          />
        </Grid>
      ),
    },
    {
      label: "Ürün Grup İsmi ve Fatura Satırı Eşleştirmesi",
      description:
        "Ürün grup ismi ve fatura satırlarını bu adımda listeleyebilirsiniz.",
      content: (
        <Grid item xs="auto" md="auto">
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={() => handleOpen(6)}
            title="ÜRÜN GRUP LİSTESİNİ GÖSTER"
            size="small"
            sx={{ width: "auto", minWidth: "120px", padding: "6px 16px" }}
          />
        </Grid>
      ),
    },
    {
      // 7. Adım
      label: "Yüklenim Listesi",
      description: "İlgili projenin yüklenim listesini görüntüleyin.",
      content: (
        <Box sx={{ mt: 2 }}>
          {loading && <LoadingData />}
          {error && !loading && <ErrorData error={error} />}
          {!loading && !error && (
            <>
              <TableComponent
                data={yuklenecekKDVData}
                tableColumns={yuklenilecekKDVColumns}
              />
              <Grid container spacing={2} mt={2}>
                <Grid item>
                  <ExportData
                    url={`api/indirimli_oran/fetch_grouped_invoice_with_details/${
                      fallbackUsed ? 1 : projectId
                    }/?export=excel`}
                    dosyaAdi={`yuklenilecek_KDV_listesi_${
                      fallbackUsed ? 1 : projectId
                    }.xlsx`}
                  />
                </Grid>
                <Grid item>
                  <MyButton
                    title="Kapat"
                    variant="contained"
                    color="btnActiveColor"
                    onClick={() => setYuklenecekKDVData([])}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      ),
    },
    {
      label: "Kullanılan Malzeme Miktarı",
      description:
        "Karkas/Yığma seçimi yaparak malzeme kullanımını hesaplayın.",
      content: (
        <Grid item xs="auto" md="auto">
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={() => handleOpen(8)}
            title="MALZEME KULLANIMI HESAPLA"
            size="small"
            sx={{ width: "auto", minWidth: "120px", padding: "6px 16px" }}
          />
        </Grid>
      ),
    },
    {
      // 9. Adım
      label: "Metrekare Oranı Ve Yüklenim Payı",
      description: "KDV yükü hesaplaması otomatik yapılır.",
      content: (
        <Box sx={{ mt: 2 }}>
          {loadingKdvLoad && <LoadingData />}
          {errorKdvLoad && !loadingKdvLoad && (
            <ErrorData error={errorKdvLoad} />
          )}
          {!loadingKdvLoad && !errorKdvLoad && kdvLoadData && (
            <TableComponent
              data={[kdvLoadData]}
              tableColumns={kdvLoadColumns}
              showFatura={true}
            />
          )}
        </Box>
      ),
    },
    {
      // 10. Adım
      label: "İndirimli Oran İade edilecek Vergi Tutarı hesap tablosu",
      description:
        "Bu adımda iade edilecek KDV tablosu otomatik olarak yüklenir.",
      content: (
        <Box sx={{ mt: 2 }}>
          {loadingIadeKdv && <LoadingData />}
          {errorIadeKdv && !loadingIadeKdv && (
            <ErrorData error={errorIadeKdv} />
          )}
          {!loadingIadeKdv && !errorIadeKdv && iadeKdvData.length > 0 && (
            <>
              <TableComponent
                data={iadeKdvData}
                tableColumns={kdvReportColumns}
                showFatura={true}
              />
              <Grid container spacing={2} mt={2}>
                <Grid item>
                  <ExportData
                    url={`api/indirimli_oran/generate_kdv_report/?format=excel&project_id=${projectId}&tckn=${
                      tckn || formData.vkn_no || "9520578377"
                    }`}
                    dosyaAdi={`kdv_report_${projectId}.xlsx`}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      ),
    },
    {
      // 11. Adım -> Sadece buton, IndirimliKayitForm(11) ile açılabilir
      label: "Karşıt İnceleme Özet Tablosu",
      description:
        "Başlangıç ve bitiş tarihleri ile KDV raporunu görüntüleyin.",
      content: (
        <Grid item xs="auto" md="auto">
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={() => handleOpen(11)}
            title="KARŞIT İNCELEME RAPORU GETİR"
            size="small"
            sx={{ width: "auto", minWidth: "120px", padding: "6px 16px" }}
          />
        </Grid>
      ),
    },
    {
      label: "Karşıt İnceleme Yüklenilecek Özet",
      description:
        "Yüklenilecek KDV için karşıt inceleme özet raporu otomatik yüklenir.",
      content: (
        <Box sx={{ mt: 2 }}>
          {karsitYuklenilecekLoading && <LoadingData />}
          {!karsitYuklenilecekLoading && pivotDataWithNames.length > 0 && (
            <>
              <TableComponent
                data={pivotDataWithNames}
                tableColumns={dynamicYuklenilecekColumns.map((col) => ({
                  ...col,
                  // Hücre metnini sola yaslamak için:
                  Cell: ({ value }) => (
                    <div style={{ textAlign: "left", padding: "10px" }}>
                      {value}
                    </div>
                  ),
                }))}
                showFatura={true}
              />
              <Grid container spacing={2} mt={2}>
                <Grid item>
                  <ExportData
                    url={`api/indirimli_oran/karsit-inceleme-summary-yuklenilecek/${projectId}?excel=true`}
                    dosyaAdi={`karsit_inceleme_ozet_${projectId}.xlsx`}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      ),
    },
  ];

  let stepsToRender = allSteps;
  if (mode === "readonly" && selectedProject) {
    stepsToRender = allSteps.filter((_, idx) =>
      [6, 8, 9, 10, 11].includes(idx)
    );
  }

  const handleStepClick = (stepIndex) => {
    if (stepIndex >= 6 && !step6Completed) {
      // 6. adım tamamlanmadan bu adımlara geçişe izin verme
      return;
    }
    setActiveStep(stepIndex);
  };
  // IndirimliOranListesi.js

  const handleNext = () => {
    // "stepsToRender" içinde 6. adım, index olarak 5'e denk gelebilir (0’dan sayılıyor).
    // Yani activeStep === 5 => 6. adım
    if (activeStep === 5 && !step6Completed) {
      notify("Lütfen 6. adımı tamamlamadan ilerleyemezsiniz!", "warning");
      return; // Bu şekilde 7. adıma geçişi engellersiniz
    }

    if (activeStep < stepsToRender.length - 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography color="error" variant="body2" mb={2}>
        *Eğer daha önce bu işlemleri yapmadıysanız lütfen önce yükleme
        işlemlerini tamamlayınız!
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepsToRender.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              onClick={() => handleStepClick(index)}
              sx={{
                cursor: "pointer",
                "& .MuiStepIcon-root": {
                  fontSize: "2.5rem",
                  color: activeStep === index ? "primary.main" : "grey.500",
                },
              }}
            >
              <Typography
                variant={activeStep === index ? "h6" : "body1"}
                sx={{
                  fontWeight: activeStep === index ? "bold" : "normal",
                }}
              >
                {step.label}
              </Typography>
            </StepLabel>
            {activeStep === index && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="textSecondary" mb={1}>
                  {step.description}
                </Typography>
                {step.content}

                {/* Butonlar */}
                <Box sx={{ mt: 2 }}>
                  {/* DEVAM ET butonu (son adım hariç) */}
                  {index < stepsToRender.length - 1 && (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mr: 1 }}
                      >
                        DEVAM ET
                      </Button>
                    </>
                  )}

                  {/* GERİ butonu (ilk adım hariç) */}
                  {index > 0 && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      GERİ
                    </Button>
                  )}

                  {/* DEVAM ET butonu varsa, altında metin göster */}
                  {index < stepsToRender.length - 1 && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ display: "block", mt: 1 }}
                    >
                      Bu adımı tamamladıysanız, devam et butonuna basınız.
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Step>
        ))}
      </Stepper>
      {open && (
        <IndirimliKayitForm
          isVisible={open}
          toggleModal={handleClose}
          initialState={newCustomer}
          formSection={formSection}
          formData={formData}
          setFormData={setFormData}
          tckn={tckn}
          setStep6Completed={setStep6Completed} // <-- Bu şekilde
        />
      )}
    </Box>
  );
};

export default IndirimliOranListesi;
