import React from "react";
import { Outlet } from "react-router-dom";
import { 
  AppBar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Typography
} from "@mui/material";

import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import useAuthCall from "../hooks/useAuthCall";
import MenuListItems from "../components/Navigation/MenuListItems";

// Drawer açıkken genişliği
const drawerWidth = 240;

// Alt kısım (telif) bileşeni
const TelifHakki = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        height: "100%",
      }}
    >
      <Typography align="center" color="secondary.second" variant="caption">
        Copyright © 2024 Müşavir Online
        <br /> Bir G-5 Tech ürünüdür.
      </Typography>
    </Box>
  );
};

function Dashboard(props) {
  const { logout } = useAuthCall();
  const { window, colorMode, theme } = props;

  // Mobil Drawer açık/kapalı yönetimi
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // Drawer'ın mini (kapalı) veya açık olup olmadığını tutan state
  // true => kapalı (sadece logo görünür), false => açık (menü + telif görünür)
  // Başlangıçta sol menünün açık olmasını istiyorsanız false olarak ayarlayın:
  const [miniDrawer, setMiniDrawer] = React.useState(false);

  // Mobil Drawer aç/kapa
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Masaüstü Drawer daraltma/büyültme
  const handleToggleMiniDrawer = () => {
    setMiniDrawer((prev) => !prev);
  };

  // Mobilde Drawer kapandığında hangi container'ı kullanacağını belirtiyoruz
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", maxWidth: "100%" }}>
      <CssBaseline />

      {/* ÜST APPBAR */}
      <AppBar
        position="fixed"
        sx={{
          // Drawer daralıp genişledikçe AppBar genişliği de değişiyor:
          width: { md: `calc(100% - ${miniDrawer ? 72 : drawerWidth}px)` },
          ml: { md: `${miniDrawer ? 72 : drawerWidth}px` },
          backgroundColor: "primary.main",
          color: "#EEEEEE",
        }}
      >
        <Toolbar>
          {/* Sadece mobilde gösterilen hamburger menü ikonu */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          {/* Masaüstünde Drawer'ı açıp kapatan ok ikonları */}
          <IconButton
            color="inherit"
            aria-label="toggle mini drawer"
            edge="start"
            onClick={handleToggleMiniDrawer}
            sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
          >
            {miniDrawer ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
          </IconButton>

          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {/* Dilerseniz buraya AppBar başlık veya logo koyabilirsiniz */}
          </Typography>

          {/* Tema (dark/light) butonu */}
          <IconButton
            sx={{ ml: 1, my: 2 }}
            onClick={colorMode.toggleColorMode}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>

          {/* Çıkış butonu */}
          <Button
            color="btnColor"
            sx={{
              textTransform: "none",
              "&:hover": {
                backgroundColor: "rgb(0, 146, 202,0.7)",
                color: "white",
                "&.MuiSvgIcon-root": {
                  color: "white",
                },
              },
            }}
            onClick={logout}
          >
            Çıkış Yap <LogoutIcon sx={{ ml: "0.5rem", color: "inherit" }} />
          </Button>
        </Toolbar>
      </AppBar>

      {/* SOL DRAWER ALANI */}
      <Box
        component="nav"
        sx={{ width: { md: miniDrawer ? 72 : drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="sidebar"
      >
        {/* Mobil Drawer (temporary) */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "primary.main",
            },
          }}
        >
          {/* Mobilde menü ve telif her zaman görünür */}
          <MenuListItems />
          <TelifHakki />
        </Drawer>

        {/* Masaüstü Drawer (permanent) */}
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: miniDrawer ? 72 : drawerWidth,
              transition: "width 0.3s",
              overflowX: "hidden",
              backgroundColor: "primary.main",
              boxSizing: "border-box",
            },
          }}
        >
          {/* LOGO SADECE KAPALI (mini) DURUMDA GÖRÜNSÜN */}
          {miniDrawer && (
            <Box
              component="img"
              sx={{
                height: 40,
                width: "auto",
                display: "block",
                margin: "16px auto",
                transition: "all 0.3s ease-in-out",
              }}
              src="/favicon.png" // Kendi logo dosyanızın yolunu girin
              alt="Müşavir Online Logo"
            />
          )}

          {/* Drawer açıkken menü ve telif gösterilsin, kapalıyken gizlensin */}
          {!miniDrawer && (
            <>
              <MenuListItems />
              <TelifHakki />
            </>
          )}
        </Drawer>
      </Box>

      {/* ANA İÇERİK ALANI */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { md: `calc(100% - ${miniDrawer ? 72 : drawerWidth}px)` },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Toolbar />
        {/* Router'dan gelen içerik */}
        <Outlet />
      </Box>
    </Box>
  );
}

export default Dashboard;
