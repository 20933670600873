import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";

const TedarikTablo = ({ currentTCKN }) => {
  const [allSellers, setAllSellers] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [errorSellers, setErrorSellers] = useState(false);
  const [loadingSellers, setLoadingSellers] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const axiosWithToken = useAxios();

  useEffect(() => {
    if (currentTCKN) {
      const fetchSellers = async () => {
        const url = `api/indirilecek_KDV/all_gcb_seller_summary?tckn=${currentTCKN}`;
        setErrorSellers(false);
        setLoadingSellers(true);
        try {
          const response = await axiosWithToken(url);
          if (response.status === 200) {
            const data = response.data;
            // Gelen veriyi tabloya uygun forma dönüştürüyoruz
            const transformedData = data.map((item) => ({
              beyannameTarihi: item["Beyanname Tarihi"],
              beyannameNo: item["Beyanname No"],
              gtip: item["GTİP"],
              miktar: item["Miktar"],
              saticiVknTckn: item["Satıcı VKN/TCKN"],
              saticiUnvan: item["Satıcı Ad/Ünvan"],
            }));
            setAllSellers(transformedData);
            setOriginalData(transformedData);
          } else {
            console.error("Veri yükleme hatası:", response.statusText);
          }
        } catch (error) {
          console.error("İstek sırasında hata oluştu:", error);
          setErrorSellers(true);
          setAllSellers([]);
        } finally {
          setLoadingSellers(false);
        }
      };
      fetchSellers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTCKN]);

  // Tablo sütunlarına tıklayınca sıralama yapmayı sağlıyoruz
  const handleRequestSort = (property) => {
    if (orderBy === property) {
      if (order === "asc") {
        setOrder("desc");
      } else if (order === "desc") {
        // Aynı sütuna üçüncü kez tıklandığında sıralamayı sıfırlıyoruz
        setOrder("");
        setOrderBy("");
        setAllSellers(originalData); 
      } else {
        setOrder("asc");
      }
    } else {
      setOrder("asc");
      setOrderBy(property);
    }
  };

  // Sıralama mantığı
  const sortedData = () => {
    if (!orderBy) {
      return allSellers;
    }
    return allSellers.slice().sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  return (
    <>
      <Typography
        component={"h3"}
        variant={"h5"}
        align="center"
        color="secondary.second"
        mt={2}
      >
        İmalatçı / Tedarikçi Firma Bilgileri
      </Typography>
      {allSellers.length ? (
        <TableContainer
          component={Paper}
          color="secondary.second"
          className="myscrool"
          sx={{
            mt: 1,
            width: "95%",
            maxWidth: "95%",
            backgroundColor: "tableColor.main",
            position: "relative",
            maxHeight: "450px",
            minHeight: "450px",
            overflowY: "scroll",
            py: 1,
          }}
        >
          <Table
            aria-label="seller-summary-table"
            sx={{
              color: "secondary.second",
              "& .MuiTableCell-root": {
                color: "secondary.second !important",
              },
              "& .MuiSvgIcon-root": {
                color: "secondary.second !important",
              },
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                top: "-15px",
                zIndex: 1,
                backgroundColor: "tableColor.main",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "180px",
                  }}
                  align="left"
                  sortDirection={orderBy === "beyannameNo" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "beyannameNo"}
                    direction={orderBy === "beyannameNo" ? order : "asc"}
                    onClick={() => handleRequestSort("beyannameNo")}
                  >
                    BEYANNAME NO
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "180px",
                  }}
                  align="center"
                  sortDirection={orderBy === "beyannameTarihi" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "beyannameTarihi"}
                    direction={orderBy === "beyannameTarihi" ? order : "asc"}
                    onClick={() => handleRequestSort("beyannameTarihi")}
                  >
                    BEYANNAME TARIHI
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "120px",
                  }}
                  align="center"
                  sortDirection={orderBy === "gtip" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "gtip"}
                    direction={orderBy === "gtip" ? order : "asc"}
                    onClick={() => handleRequestSort("gtip")}
                  >
                    GTİP
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "120px",
                  }}
                  align="center"
                  sortDirection={orderBy === "miktar" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "miktar"}
                    direction={orderBy === "miktar" ? order : "asc"}
                    onClick={() => handleRequestSort("miktar")}
                  >
                    MİKTAR
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "180px",
                  }}
                  align="center"
                  sortDirection={orderBy === "saticiVknTckn" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "saticiVknTckn"}
                    direction={orderBy === "saticiVknTckn" ? order : "asc"}
                    onClick={() => handleRequestSort("saticiVknTckn")}
                  >
                    SATICI VKN/TCKN
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "280px",
                  }}
                  align="center"
                  sortDirection={orderBy === "saticiUnvan" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "saticiUnvan"}
                    direction={orderBy === "saticiUnvan" ? order : "asc"}
                    onClick={() => handleRequestSort("saticiUnvan")}
                  >
                    SATICI AD/ÜNVAN
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedData().map((item, i) => (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      minWidth: "180px",
                    }}
                    align="left"
                    component="th"
                    scope="row"
                    title={item.beyannameNo}
                  >
                    {item.beyannameNo}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      minWidth: "180px",
                    }}
                    align="center"
                    title={item.beyannameTarihi}
                  >
                    {item.beyannameTarihi}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      minWidth: "120px",
                    }}
                    align="center"
                    title={item.gtip}
                  >
                    {item.gtip}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      minWidth: "120px",
                    }}
                    align="center"
                    title={item.miktar}
                  >
                    {item.miktar}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      minWidth: "180px",
                    }}
                    align="center"
                    title={item.saticiVknTckn}
                  >
                    {item.saticiVknTckn}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "pre-line", 
                      textAlign: "center",
                      minWidth: "280px",
                    }}
                    align="center"
                    title={item.saticiUnvan}
                  >
                    {item.saticiUnvan}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      {loadingSellers && <LoadingData />}
      {errorSellers && <ErrorData error={errorSellers} />}
      {!errorSellers && !allSellers.length && !loadingSellers && (
        <Typography component={"h4"} color="secondary.second" m={3}>
          Görüntülenecek İmalatçı/Tedarikçi Firma verisi bulunamadı.
        </Typography>
      )}
    </>
  );
};

export default TedarikTablo;
