// store.jsx
import { configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";


import authReducer from "../features/authSlice";
import { customersApi } from "../services/customers";
import { teamMembersApi } from "../services/teamMembers";
import { indirimliOranApi } from "../services/indirimliOran"; 

const persistConfig = {
  key: "root",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedReducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [teamMembersApi.reducerPath]: teamMembersApi.reducer,
    [indirimliOranApi.reducerPath]: indirimliOranApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });

    return defaultMiddleware
      .concat(customersApi.middleware)
      .concat(teamMembersApi.middleware)
      .concat(indirimliOranApi.middleware);
  },
  devTools: process.env.NODE_ENV !== "production",
});

export let persistor = persistStore(store);
export default store;
