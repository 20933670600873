// src/components/IadeTurleriIslemleri/MevcutProjelerListesi.js

import React from "react";
import { useListProjectsQuery } from "../../services/indirimliOran";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MevcutProjelerListesi = ({ onSelectProject }) => {
  // API'den projeleri çekiyoruz
  const { data: projects = [], isLoading, isError } = useListProjectsQuery();

  if (isLoading) {
    return (
      <Box sx={{ mt: 2 }}>
        <CircularProgress />
        <Typography>Projeler Yükleniyor...</Typography>
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        Proje listesi yüklenirken bir hata oluştu.
      </Typography>
    );
  }

  if (!projects.length) {
    return <Typography sx={{ mt: 2 }}>Hiç proje bulunamadı.</Typography>;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Mevcut Projeler
      </Typography>

      {projects.map((proj) => (
        <Accordion
          key={proj.id}
          sx={{ border: "1px solid #ccc", mb: 1, borderRadius: 1 }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {/* Örneğin: #11 - deneme (Kayıt No: 676767) */}
            <Typography>
              #{proj.id} - {proj.name} (Kayıt No: {proj.registration_no})
            </Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
            {/* Sadece kayıt tarihi gösteriliyor (Oluşturulma/Güncellenme tarihleri yok) */}
            <Typography>
              <strong>Kayıt Tarihi:</strong> {proj.registration_date}
            </Typography>

            {/* Seç butonunu sol altta tutmak için justifyContent: "flex-start" */}
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-start" }}>
              <Button
                variant="outlined"
                color="btnActiveColor"
                onClick={() => onSelectProject(proj)}
              >
                Seç
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default MevcutProjelerListesi;
