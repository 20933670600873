import { useDispatch } from "react-redux";
import {
  fetchFail,
  fetchStart,
  loginSuccess,
  logoutSuccess,
  updateUserSuccess,
} from "../features/authSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useAxios, { axiosWithPublic } from "./useAxios";
import { notify, notifyHtml } from "../helper/notify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const useAuthCall = () => {
  const dispatch = useDispatch();
  const axiosWithToken = useAxios();
  const navigate = useNavigate();

  const login = async (userInfo) => {
    dispatch(fetchStart());
    try {
      const { data } = await axios.post(
        `${BASE_URL}api/authentication/login`,
        userInfo
      );
      dispatch(loginSuccess(data));
      if (data?.kullanici_tipi === "standart") {
        navigate("/musavir-online/paket-yukselt/");
      } else {
        if (
          data?.bagli_oldugu_oda &&
          data?.muhur_no &&
          data?.vergi_dairesi &&
          data?.vkn_no &&
          data?.ymm_adres &&
          data?.phone
        ) {
          navigate("/musavir-online");
          notify("Uygulamaya Hoş Geldiniz!", "success");
        } else {
          navigate("/musavir-online/profilim");
          notify(
            "Profilinizde eksik bilgiler mevcut. Lütfen işlem yapmaya başlamadan önce eksik bilgilerinizi tamamlayınız!",
            "warning"
          );
        }
      }
    } catch (error) {
      dispatch(fetchFail());
      notify(
        error.response.data.message ||
          "Bir hata oluştu. Lütfen tekrar deneyiniz!"
      );
    }
  };
  const updateUser = async (userData) => {
    dispatch(fetchStart());
    try {
      await axiosWithToken.put(`${BASE_URL}api/user/update-profile/`, userData);
      getUser();
      notify("Güncelleme işlemi başarılı!", "success");
    } catch (error) {
      dispatch(fetchFail());
      notifyHtml(error.response.data);
    }
  };
  const getUser = async () => {
    dispatch(fetchStart());
    try {
      const { data } = await axiosWithToken.get(`${BASE_URL}api/user/me`);
      dispatch(updateUserSuccess(data));
    } catch (error) {
      dispatch(fetchFail());
    }
  };
  const forgotPassword = async (email) => {
    await axiosWithPublic.post(`${BASE_URL}api/user/forget-password`, {
      email,
    });
    notify(
      "Şifremi unuttum talebiniz başarıyla alınmıştır. Mailinize gerekli bilgiler gönderildi.",
      "success"
    );
  };
  const changePassword = async (data) => {
    await axiosWithToken.post(`${BASE_URL}api/user/change-password/`, data);
    notify("Şifreniz başarıyla güncellendi!", "success");
  };
  const logout = async () => {
    await axiosWithToken.post(`${BASE_URL}api/authentication/logout`);
    dispatch(logoutSuccess());
    navigate("/");
    notify("Güle Güle 🙋‍♂️ Tekrar bekleriz!", "success");
  };

  return { login, logout, updateUser, getUser, forgotPassword, changePassword };
};

export default useAuthCall;
