import { Box, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import Select from "react-select";
import { useGetCustomersQuery } from "../../services/customers";

const customStyles = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: "250px",
    overflow: "auto",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#454F5B"
      : state.isFocused
      ? "#D3D3D3"
      : "#fff",
    color: state.isSelected ? "#fff" : "#454F5B",
    padding: 10,
    position: "static",
    zIndex: 1000,
  }),
};

const CustomerSelect = ({ selectedMusteri, setSelectedMusteri, msg }) => {
  const { data: customers, isLoading, isError } = useGetCustomersQuery();

  // useEffect'ı early return'lardan önce çağırıyoruz
  useEffect(() => {
    if (customers && !selectedMusteri) {
      setSelectedMusteri(null);
    }
  }, [customers, setSelectedMusteri, selectedMusteri]);

  // Müşterileri seçeneklere dönüştürme
  const musteriSelect = useMemo(() => {
    return customers?.map((item) => ({
      value: item.tckn,
      label: `${item.ad_soyad} - ${item.tckn}`,
    })) || [];
  }, [customers]);

  // Yüklenme durumunu kontrol ediyoruz
  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Hata durumunda mesaj gösteriyoruz
  if (isError) {
    return (
      <Typography variant="body2" color="error">
        Müşteriler yüklenirken bir hata oluştu.
      </Typography>
    );
  }

  return (
    <Box sx={{ position: "relative", zIndex: 5 }}>
      <Select
        value={selectedMusteri}
        onChange={setSelectedMusteri}
        options={musteriSelect}
        isClearable={true}
        styles={customStyles}
        placeholder={msg || "Müşteri Seçiniz"}
      />
    </Box>
  );
};

export default CustomerSelect;
