// src/pages/IndirimliOran.js (veya benzeri)

import React, { useState } from "react";
import { Box, Grid, Alert, Typography } from "@mui/material";
import PageHeader from "../components/Commons/PageHeader";
import MyButton from "../components/Commons/MyButton";
import CustomerRegistrationModal from "../components/Forms/CustomerForm";
import { newCustomer } from "../helper/initialData";
import Loading from "../components/Commons/Loading";
import CustomerSelect from "../components/Commons/CustomerSelect";
import { useGetCustomersQuery } from "../services/customers";

// Yeni eklenen importlar:
import IndirimliOranListesi from "../components/IadeTurleriIslemleri/IndirimliOranListesi";
import MevcutProjelerListesi from "../components/IadeTurleriIslemleri/MevcutProjelerListesi";

const IndirimliOran = () => {
  // Mükellef seçimi vs. (mevcut yapınız)
  const { isLoading: loading } = useGetCustomersQuery();
  const [selectedMusteri, setSelectedMusteri] = useState("");

  // Modal (müşteri kaydı) aç/kapat
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Yeni state'ler:
  const [showNewProjectStepper, setShowNewProjectStepper] = useState(false);
  const [showExistingProjects, setShowExistingProjects] = useState(false);

  // Mevcut projelerden bir proje seçilirse
  const [selectedProject, setSelectedProject] = useState(null);

  if (loading) return <Loading />;

  return (
    <Box sx={{ p: 1 }}>
      <PageHeader text="İndirimli Oran" />

      <Grid container spacing={3} flexDirection="column" mt={1}>
        <Grid item xs={8} md={3}>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={handleOpen}
            title="Yeni Mükellef Kaydı Oluştur"
          />
        </Grid>

        {/* Müşteri/Proje Seçimi */}
        <Grid item xs={10} md={5}>
          <CustomerSelect
            selectedMusteri={selectedMusteri}
            setSelectedMusteri={setSelectedMusteri}
            msg="İşlem yapmak istediğiniz projeyi seçiniz veya arama yapmak için yazınız"
          />
        </Grid>
      </Grid>

      {/* Uyarı: müşteri seçilmedi */}
      {!selectedMusteri && (
        <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
          İşlem yapmak istediğiniz projeyi seçiniz veya arama yapmak için
          yazınız
        </Alert>
      )}

      {/* Müşteri seçildiyse 2 yeni buton */}
      {selectedMusteri && (
        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            title="Yeni Proje Oluştur"
            onClick={() => {
              setShowNewProjectStepper(true);
              setShowExistingProjects(false);
              setSelectedProject(null);
            }}
          />
          <MyButton
            variant="contained"
            color="btnActiveColor"
            title="Mevcut Projeleri Görüntüle"
            onClick={() => {
              setShowNewProjectStepper(false);
              setShowExistingProjects(true);
              setSelectedProject(null);
            }}
          />
        </Box>
      )}

      {/* 1) Yeni Proje Oluştur -> 12 adımlık stepper (IndirimliOranListesi) */}
      {showNewProjectStepper && (
        <IndirimliOranListesi tckn={selectedMusteri?.value} />
      )}

      {/* 2) Mevcut Projeleri Görüntüle -> Proje listesi */}
      {showExistingProjects && !selectedProject && (
        <MevcutProjelerListesi
          onSelectProject={(proj) => setSelectedProject(proj)}
        />
      )}

      {/* Eğer mevcut projelerden bir proje seçilirse: sadece GET odaklı adımlar */}
      {selectedProject && (
        <IndirimliOranListesi
          tckn={selectedMusteri?.value}
          selectedProject={selectedProject}
          mode="readonly" // Bu parametreye göre, 7,9,10,11,12 adımları gösterebilirsiniz.
        />
      )}

      {/* Yeni Proje Kayıt Modal */}
      {open && (
        <CustomerRegistrationModal
          isVisible={open}
          toggleModal={handleClose}
          initialState={newCustomer}
        />
      )}
    </Box>
  );
};

export default IndirimliOran;
