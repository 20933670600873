// src/services/indirimliOran.js

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Standart baseQuery tanımlaması
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Token ${token}`);
    }
    return headers;
  },
});

// Logging ekleyerek tüm istekleri merkezi olarak kontrol edebileceğiniz baseQuery fonksiyonu
const baseQueryWithLogging = async (args, api, extraOptions) => {
  console.log("RTK Query Request:", args);
  const result = await baseQuery(args, api, extraOptions);
  console.log("RTK Query Response:", result);
  return result;
};

export const indirimliOranApi = createApi({
  reducerPath: "indirimliOranApi",
  baseQuery: baseQueryWithLogging,
  endpoints: (builder) => ({
    // -------------------------------------------------
    // Önceki metotlar:
    // -------------------------------------------------
    createOrSelectProject: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/create_or_select_project/",
        method: "POST",
        body: payload,
      }),
    }),
    addWorkerStatistics: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/add_worker_statistics/",
        method: "POST",
        body: payload,
      }),
    }),
    addIndependentSection: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/add_independent_section/",
        method: "POST",
        body: payload,
      }),
    }),
    createBuilding: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/create_building/",
        method: "POST",
        body: payload,
      }),
    }),
    addHousingDetails: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/add_housing_details/",
        method: "POST",
        body: payload,
      }),
    }),
    getUserInvoicesAndProducts: builder.query({
      query: (vknNo) =>
        `api/indirimli_oran/get_user_invoices_and_products/?vkn_no=${vknNo}`,
    }),

    // -------------------------------------------------
    // 6. Kısım için eklenen metotlar:
    // -------------------------------------------------
    fetchProjectInvoiceData: builder.query({
      query: ({ projectId, vergiNo }) =>
        `api/indirimli_oran/fetch_project_invoice_data/${projectId}/?vergi_no=${vergiNo}`,
      transformResponse: (baseResponse) => baseResponse.data || [],
    }),
    createInvoiceGroup: builder.mutation({
      query: ({ projectId, ...payload }) => ({
        url: `api/indirimli_oran/create_invoice_group/${projectId}/`,
        method: "POST",
        body: payload,
      }),
    }),

    // -------------------------------------------------
    // 8) Malzeme Kullanımı Hesaplama
    // -------------------------------------------------
    calculateMaterialUsage: builder.mutation({
      query: ({ projectId, construction_type }) => ({
        url: `api/indirimli_oran/calculate_material_usage/${projectId}/`,
        method: "POST",
        body: { construction_type }, // "karkas" veya "yigma"
      }),
    }),

    // -------------------------------------------------
    // 9) KDV Yükü Hesaplaması
    // -------------------------------------------------
    calculateKdvLoad: builder.query({
      query: (projectId) => `api/indirimli_oran/calculate_kdv_load/${projectId}/`,
    }),
    generateKdvReport: builder.query({
      query: ({ projectId, tckn }) =>
        `api/indirimli_oran/generate_kdv_report/?format=json&project_id=${projectId}&tckn=${tckn}`,
    }),

    karsitIncelemeSummary: builder.query({
      query: ({ projectId, vknNo, start_date, end_date }) =>
        `api/indirimli_oran/karsit-inceleme-summary/${projectId}/${vknNo}/?start_date=${start_date}&end_date=${end_date}`,
    }),
    // -------------------------------------------------
    // 12) Karşıt İnceleme Yüklenilecek Özet
    // -------------------------------------------------
    karsitIncelemeSummaryYuklenilecek: builder.query({
      query: ({ projectId, vknNo }) =>
        `api/indirimli_oran/karsit-inceleme-summary-yuklenilecek/${projectId}/${vknNo}/`,
    }),
    listProjects: builder.query({
      query: () => `api/indirimli_oran/list_projects/`,
      transformResponse: (baseResponse) => baseResponse.projects || [],
    }),
  }),
});

export const {
  useCreateOrSelectProjectMutation,
  useAddWorkerStatisticsMutation,
  useAddIndependentSectionMutation,
  useCreateBuildingMutation,
  useAddHousingDetailsMutation,
  useGetUserInvoicesAndProductsQuery,
  useFetchProjectInvoiceDataQuery,
  useCreateInvoiceGroupMutation,
  useCalculateMaterialUsageMutation,
  useLazyCalculateKdvLoadQuery,
  useLazyGenerateKdvReportQuery,
  useLazyKarsitIncelemeSummaryQuery,
  useLazyKarsitIncelemeSummaryYuklenilecekQuery,
  useListProjectsQuery,
} = indirimliOranApi;
