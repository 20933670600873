// src/components/Forms/IndirimliKayitForm.js

import React, { useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
  ListItemText,
  Grid,
  Autocomplete,
} from "@mui/material";

// Eski (customers) servisleriniz
import {
  usePostCustomerMutation,
  useUpdateCustomerMutation,
} from "../../services/customers";

// YENİ RTK Query servis (indirimliOran)
import {
  useCreateOrSelectProjectMutation,
  useAddWorkerStatisticsMutation,
  useAddIndependentSectionMutation,
  useCreateBuildingMutation,
  useAddHousingDetailsMutation,
  useGetUserInvoicesAndProductsQuery,
  useFetchProjectInvoiceDataQuery,
  useCreateInvoiceGroupMutation,
  useCalculateMaterialUsageMutation,
  useLazyCalculateKdvLoadQuery,
  useLazyGenerateKdvReportQuery,
  useLazyKarsitIncelemeSummaryQuery,
  useLazyKarsitIncelemeSummaryYuklenilecekQuery,
} from "../../services/indirimliOran";

import TableComponent from "../Tables/TableComponent";
import {
  karsitIncelemeSummaryColumns,
  kdvLoadColumns,
  kdvReportColumns,
  materialUsageColumns,
  totalKdvPerPeriodColumns,
} from "../../helper/columns";
import ExportData from "../Commons/ExportData";
import { notify, notifyHtml } from "../../helper/notify";

// -------------------------------------------------
// 1) Yardımcı Fonksiyonlar
// -------------------------------------------------
const stepsWithPost = [1, 4, 5, 6];
function getMonthCount(start, end) {
  if (!start || !end) return 0;
  const [startYear, startM] = start.split("-").map(Number);
  const [endYear, endM] = end.split("-").map(Number);
  return (endYear - startYear) * 12 + (endM - startM) + 1;
}

function getFirstDayOfMonth(year, month) {
  const dt = new Date(year, month - 1, 1);
  return dt.toISOString().split("T")[0];
}

function getMonthNameTR(monthNumber) {
  const aylar = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  return aylar[monthNumber - 1];
}

function computeMonthLabel(startYear, startMonth, index) {
  const totalMonthsFromStart = startMonth - 1 + index;
  const year = startYear + Math.floor(totalMonthsFromStart / 12);
  const month = (totalMonthsFromStart % 12) + 1;
  return `${getMonthNameTR(month)} ${year}`;
}

// -------------------------------------------------
// 2) Varsayılan Objeler
// -------------------------------------------------
const defaultHousingItem = {
  floor: "1",
  section_no: "1",
  characteristic: "Mesken",
  net_area: "",
  gross_area: "",
  is_sold: false,
  invoices: [],
  product_id: null,
  building_id: "",
};

const defaultBuilding = {
  building_no: "",
  total_floors: "",
  sections: {
    mesken: { count: "", area: "" },
    ortak: { count: "", area: "" },
  },
  housing_details: [],
};

// -------------------------------------------------
// 3) TextField Stil Ayarları
// -------------------------------------------------
const textFieldStyle = {
  backgroundColor: "#f9f9f9",
  borderRadius: 1,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ddd",
    },
    "&:hover fieldset": {
      borderColor: "#aaa",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0066cc",
    },
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
    {
      WebkitAppearance: "none",
      margin: 0,
    },
  flex: 1,
};

// -------------------------------------------------
// 4) IndirimliKayitForm Bileşeni
// -------------------------------------------------
const IndirimliKayitForm = ({
  isVisible,
  toggleModal,
  formData,
  setFormData,
  formSection,
  tckn,
  setStep6Completed,
}) => {
  // Eski (customers)
  const [updateCustomer] = useUpdateCustomerMutation();
  const [postCustomer] = usePostCustomerMutation();

  // Yeni (indirimliOran)
  const [createProject] = useCreateOrSelectProjectMutation();
  const [addWorkerStats] = useAddWorkerStatisticsMutation();
  const [addIndependentSection] = useAddIndependentSectionMutation();
  const [createBuilding] = useCreateBuildingMutation();
  const [addHousingDetails] = useAddHousingDetailsMutation();

  // 6. Kısım için yeni mutation
  const [createInvoiceGroup] = useCreateInvoiceGroupMutation();

  // 8. Kısım: Proje Yapı Tpiniiçin mutation
  const [calculateMaterialUsage, { isLoading: calculateLoading }] =
    useCalculateMaterialUsageMutation();

  // Hata / Uyarı durumları
  const [errors, setErrors] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  // İşçi istatistikleri
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [workers, setWorkers] = useState([]);

  // 8. Kısım: Malzeme Kullanımı için
  const [constructionType, setConstructionType] = useState("karkas"); // "karkas" veya "yigma"
  const [materialUsageData, setMaterialUsageData] = useState({});

  // 9. Kısım: KDV Yükü Hesaplama
  const [
    triggerCalculateKdvLoad,
    { data: kdvLoadData, isLoading: kdvLoadLoading },
  ] = useLazyCalculateKdvLoadQuery();
  const handleCalculateKdvLoad = () => {
    const projectIdNum = formData.project_id || 1;
    triggerCalculateKdvLoad(projectIdNum);
  };

  // 10. Kısım: indirimli oran iade
  const [
    triggerGenerateKdvReport,
    { data: kdvReportData, isLoading: kdvReportLoading },
  ] = useLazyGenerateKdvReportQuery();
  const handleGenerateKdvReport = () => {
    const projectIdNum = formData.project_id || 1;
    const tcknParam = tckn || tckn || "";
    triggerGenerateKdvReport({ projectId: projectIdNum, tckn: tcknParam });
  };

  // 11. Kısım: Karşıt İnceleme Özet Raporu
  const getDialogProps = (section) => {
    if (section === 11 || section === 12) {
      return {
        maxWidth: false, // Material UI'nun varsayılan genişlik kısıtlamasını iptal ediyoruz
        fullWidth: false, // fullWidth'i kapatıyoruz, aksi halde 100% genişler
        PaperProps: {
          style: {
            minWidth: "90%", // Diyaloğu ekranın %90'ı kadar geniş yap
            // Dilerseniz minHeight da verebilirsiniz: minHeight: "70vh",
          },
        },
      };
    }

    // Diğer adımlar (1-10 vs.) için varsayılan ayar
    return {
      maxWidth: "md",
      fullWidth: true,
    };
  };
  const [
    triggerKarsitSummary,
    { data: karsitSummaryData, isLoading: karsitSummaryLoading },
  ] = useLazyKarsitIncelemeSummaryQuery();
  const [karsitStartDate, setKarsitStartDate] = useState("2023-01-01");
  const [karsitEndDate, setKarsitEndDate] = useState("2025-01-31");
  const handleGenerateKarsitIncelemeSummary = () => {
    const projectIdNum = formData.project_id || 1;
    const vknNoParam = tckn || tckn || "";
    triggerKarsitSummary({
      projectId: projectIdNum,
      vknNo: vknNoParam,
      start_date: karsitStartDate,
      end_date: karsitEndDate,
    });
  };

  const dynamicSaticiColumns = React.useMemo(() => {
    if (!karsitSummaryData || !karsitSummaryData.satici_pivot_table?.length)
      return [];
    const baseColumns = [
      { Header: "Satıcı TCKN/VKN", accessor: "Satıcı TCKN/VKN" },
      { Header: "Satıcı Adı", accessor: "Satıcı Adı" },
    ];
    const uniqueMonths = new Set();
    karsitSummaryData.satici_pivot_table.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (!["Satıcı TCKN/VKN", "Satıcı Adı"].includes(key)) {
          uniqueMonths.add(key);
        }
      });
    });
    const dynamicCols = Array.from(uniqueMonths).map((month) => ({
      Header: month,
      accessor: month,
    }));
    return [...baseColumns, ...dynamicCols];
  }, [karsitSummaryData]);

  // 12. Kısım: Karşıt İnceleme Yüklenilecek Özet
  const [
    triggerKarsitYuklenilecekSummary,
    { data: karsitYuklenilecekData, isLoading: karsitYuklenilecekLoading },
  ] = useLazyKarsitIncelemeSummaryYuklenilecekQuery();
  const handleGenerateKarsitIncelemeSummaryYuklenilecek = () => {
    const projectIdNum = formData.project_id || 1;
    const vknNoParam = tckn || tckn || "";
    triggerKarsitYuklenilecekSummary({
      projectId: projectIdNum,
      vknNo: vknNoParam,
    });
  };

  const pivotDataWithNames = React.useMemo(() => {
    if (!karsitYuklenilecekData || !karsitYuklenilecekData.data) return [];
    const rows = karsitYuklenilecekData.data;
    const namesDict = karsitYuklenilecekData.supplier_names || {};
    return rows.map((row) => {
      const newRow = { ...row };
      const tckn = row["Satıcı TCKN/VKN"];
      if (namesDict[tckn]) {
        newRow["Satıcı TCKN/VKN"] = namesDict[tckn];
      }
      return newRow;
    });
  }, [karsitYuklenilecekData]);
  const dynamicYuklenilecekColumns = React.useMemo(() => {
    if (!pivotDataWithNames.length) return [];
    const baseColumns = [{ Header: "Satıcı Adı", accessor: "Satıcı TCKN/VKN" }];
    const uniqueMonths = new Set();
    pivotDataWithNames.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "Satıcı TCKN/VKN") {
          uniqueMonths.add(key);
        }
      });
    });
    const sortedMonthKeys = Array.from(uniqueMonths).sort((a, b) =>
      a.localeCompare(b)
    );
    const dynamicCols = sortedMonthKeys.map((month) => ({
      Header: month,
      accessor: month,
    }));
    return [...baseColumns, ...dynamicCols];
  }, [pivotDataWithNames]);

  // -------------------------------------------------
  // Fatura listesini çekmek için "VKN" alanı
  // -------------------------------------------------
  const vknNo = tckn || "";
  console.log("VKN No:", vknNo);
  const { data: invoiceData, isLoading: invoiceLoading } =
    useGetUserInvoicesAndProductsQuery(vknNo, {
      skip: !vknNo,
    });

  // -------------------------------------------------
  // 6. Kısım: fetch_project_invoice_data/<projectId>/?vergi_no=<vkn>
  // -------------------------------------------------
  const projectId = formData.project_id || 1;
  const {
    data: projectInvoiceData = [],
    isLoading: projectInvoiceLoading,
    isError: projectInvoiceError,
  } = useFetchProjectInvoiceDataQuery(
    { projectId, vergiNo: vknNo },
    { skip: !projectId || !vknNo }
  );

  // 6. Kısım: İki sabit grup (Beton, Demir) tanımlıyoruz
  const [invoiceGroups, setInvoiceGroups] = useState([
    { group_name: "Beton", group_description: "", invoices: [] },
    { group_name: "Demir", group_description: "", invoices: [] },
  ]);

  // Grup verilerini günceller
  const handleGroupChange = (index, field, value) => {
    setInvoiceGroups((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated;
    });
  };

  // Yeni grup ekler
  const handleAddGroup = () => {
    // Eğer zaten 3 grup varsa (Beton, Demir, Diğer), tekrar eklenmesin
    if (invoiceGroups.length >= 3) return;

    setInvoiceGroups((prev) => [
      ...prev,
      {
        group_name: "Diğer",
        group_description: "Diğer Ürün Grupları",
        invoices: [],
      },
    ]);
  };

  // -------------------------------------------------
  // 5) OnChange Fonksiyonları
  // -------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleBuildingCountChange = (e) => {
    const newCount = parseInt(e.target.value, 10) || 0;
    setFormData((prev) => {
      const updated = { ...prev };
      updated.building_count = newCount;
      let newBuildings = [...(updated.buildings || [])];
      const currentLen = newBuildings.length;
      if (newCount > currentLen) {
        for (let i = 0; i < newCount - currentLen; i++) {
          newBuildings.push({
            ...defaultBuilding,
            sections: {
              mesken: { ...defaultBuilding.sections.mesken },
              ortak: { ...defaultBuilding.sections.ortak },
            },
            housing_details: [],
          });
        }
      } else if (newCount < currentLen) {
        newBuildings = newBuildings.slice(0, newCount);
      }
      updated.buildings = newBuildings;
      return updated;
    });
  };

  const handleBuildingSections = (bIndex, e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updated = { ...prev };
      const newBuildings = [...updated.buildings];
      const building = { ...newBuildings[bIndex] };
      const updatedSections = { ...building.sections };
      switch (name) {
        case "mesken_count": {
          updatedSections.mesken.count = value;
          const newCount = parseInt(value, 10) || 0;
          const currLen = building.housing_details.length;
          let newHousing = [...building.housing_details];
          if (newCount > currLen) {
            for (let i = 0; i < newCount - currLen; i++) {
              newHousing.push({
                ...defaultHousingItem,
                section_no: (currLen + i + 1).toString(),
                floor: "1",
              });
            }
          } else if (newCount < currLen) {
            newHousing = newHousing.slice(0, newCount);
          }
          building.housing_details = newHousing;
          break;
        }
        case "mesken_area":
          updatedSections.mesken.area = value;
          break;
        case "ortak_count":
          updatedSections.ortak.count = value;
          break;
        case "ortak_area":
          updatedSections.ortak.area = value;
          break;
        default:
          break;
      }
      building.sections = updatedSections;
      newBuildings[bIndex] = building;
      updated.buildings = newBuildings;
      return updated;
    });
  };

  const handleBuildingInfo = (bIndex, field, val) => {
    setFormData((prev) => {
      const updated = { ...prev };
      const newBuildings = [...updated.buildings];
      const building = { ...newBuildings[bIndex] };
      building[field] = val;
      if (field === "building_no") {
        building.housing_details = building.housing_details.map((hd) => ({
          ...hd,
          building_id: val || bIndex + 1,
        }));
      }
      newBuildings[bIndex] = building;
      updated.buildings = newBuildings;
      return updated;
    });
  };

  const handleHousingDetailChange = (bIndex, dIndex, field, val) => {
    setFormData((prev) => {
      // Eğer prev, buildings dizisi veya istenen bina yoksa, state’i değiştirmeden geri dönün.
      if (!prev || !prev.buildings || !prev.buildings[bIndex]) return prev;

      const updated = { ...prev };
      const newBuildings = [...updated.buildings];
      const building = { ...newBuildings[bIndex] };
      // Eğer housing_details tanımlı değilse, boş bir dizi oluşturun.
      const newHousing = building.housing_details
        ? [...building.housing_details]
        : [];

      // Eğer istenen daire (dIndex) yoksa state’i değiştirmeden çıkın.
      if (!newHousing[dIndex]) return prev;

      if (field === "section_no") {
        newHousing[dIndex] = {
          ...newHousing[dIndex],
          section_no: val === "" ? (dIndex + 1).toString() : val,
        };
      } else if (field === "floor") {
        newHousing[dIndex] = {
          ...newHousing[dIndex],
          floor: val === "" ? "1" : val,
        };
      } else if (field === "is_sold") {
        newHousing[dIndex] = {
          ...newHousing[dIndex],
          is_sold: val,
        };
      }
      // BURADA gross_area kontrolü
      else if (field === "gross_area") {
        // Kullanıcının girdiği yeni değeri sayıya çeviriyoruz
        const newGrossVal = parseFloat(val) || 0;

        // Tüm dairelerin brüt alanlarını toplayalım
        const totalGross = newHousing.reduce((acc, daire, idx) => {
          return (
            acc +
            (idx === dIndex ? newGrossVal : parseFloat(daire.gross_area) || 0)
          );
        }, 0);

        // Bina için girilen "Toplam m² (Mesken)" değeri; optional chaining ile güvenli erişim
        const meskenArea = parseFloat(building.sections?.mesken?.area) || 0;

        // Eğer toplam brüt alan, bina mesken alanını aşıyorsa uyarı verip işlemi durdurun
        if (totalGross > meskenArea) {
          notify(
            "Dairelerin toplam brüt alanı, binanın mesken alanını aşamaz!",
            "warning"
          );
          return prev; // Güncellemeyi iptal ediyoruz, state'i değiştirmeden geri dönüyoruz
        }

        // Aşmıyorsa, değeri normal şekilde kaydediyoruz
        newHousing[dIndex] = {
          ...newHousing[dIndex],
          gross_area: val,
        };
      }
      // Diğer alanlar için
      else {
        newHousing[dIndex] = {
          ...newHousing[dIndex],
          [field]: val,
        };
      }

      // Her durumda, ilgili dairenin building_id'si güncellensin
      newHousing[dIndex].building_id = building.building_no || bIndex + 1;
      building.housing_details = newHousing;
      newBuildings[bIndex] = building;
      updated.buildings = newBuildings;
      return updated;
    });
  };

  const addInvoice = (bIndex, dIndex) => {
    setFormData((prev) => {
      const updated = { ...prev };
      const newBuildings = [...updated.buildings];
      const building = { ...newBuildings[bIndex] };
      let newHousing = [...building.housing_details];
      newHousing[dIndex].invoices.push({ invoice_no: "" });
      building.housing_details = newHousing;
      newBuildings[bIndex] = building;
      updated.buildings = newBuildings;
      return updated;
    });
  };

  const handleInvoiceChange = (bIndex, dIndex, invIndex, val) => {
    setFormData((prev) => {
      const updated = { ...prev };
      const newBuildings = [...updated.buildings];
      const building = { ...newBuildings[bIndex] };
      let newHousing = [...building.housing_details];
      newHousing[dIndex].invoices[invIndex].invoice_no = val;
      newHousing[dIndex].building_id = building.building_no;
      building.housing_details = newHousing;
      newBuildings[bIndex] = building;
      updated.buildings = newBuildings;
      return updated;
    });
  };

  const removeInvoice = (bIndex, dIndex, invIndex) => {
    setFormData((prev) => {
      const updated = { ...prev };
      const newBuildings = [...updated.buildings];
      const building = { ...newBuildings[bIndex] };
      let newHousing = [...building.housing_details];
      newHousing[dIndex].invoices = newHousing[dIndex].invoices.filter(
        (_, i) => i !== invIndex
      );
      newHousing[dIndex].building_id = building.building_no;
      building.housing_details = newHousing;
      newBuildings[bIndex] = building;
      updated.buildings = newBuildings;
      return updated;
    });
  };

  const handleCreateMonths = () => {
    const total = getMonthCount(startMonth, endMonth);
    if (total <= 0) {
      setModalMessage(
        "Geçersiz tarih aralığı. Lütfen doğru bir başlangıç ve bitiş seçiniz."
      );
      setSuccessModalVisible(true);
      return;
    }
    const [startY, startM] = startMonth.split("-").map(Number);
    const newRows = [];
    for (let i = 0; i < total; i++) {
      const totalMonthsFromStart = startM - 1 + i;
      const year = startY + Math.floor(totalMonthsFromStart / 12);
      const month = (totalMonthsFromStart % 12) + 1;
      const period = getFirstDayOfMonth(year, month);
      const label = computeMonthLabel(startY, startM, i);
      newRows.push({
        label,
        period,
        worker_count: "",
        worker_expense: "",
      });
    }
    setWorkers(newRows);
  };

  const handleCalculateMaterialUsage = async () => {
    try {
      const projectIdNum = formData.project_id || 1;
      const response = await calculateMaterialUsage({
        projectId: projectIdNum,
        construction_type: constructionType,
      }).unwrap();
      // Gelen yanıt { total_area, calculated_materials: [...] } şeklinde
      setMaterialUsageData(response);
    } catch (err) {
      console.error("Malzeme kullanım hesaplama hatası:", err);
      setModalMessage("Malzeme kullanım hesaplamasında hata oluştu.");
      setSuccessModalVisible(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Gönderilecek Form Data:", JSON.stringify(formData, null, 2));
    try {
      // 1) Proje Oluşturma
      if (formSection === 1) {
        const resp = await createProject({
          name: formData.name,
          registration_no: formData.registration_no,
          registration_date: formData.registration_date,
        }).unwrap();
        console.log("Proje Oluşturma Yanıtı:", resp);
        setFormData((prev) => ({ ...prev, project_id: resp.project.id }));
        notify("Proje başarıyla oluşturuldu.", "success");
        toggleModal();
        return;
      }
      // 2) İşçi İstatistikleri
      if (formSection === 4) {
        const projectId = formData.project_id ? Number(formData.project_id) : 1;
        const worker_data = workers.map((row) => ({
          period: row.period,
          worker_count: Number(row.worker_count) || 0,
          worker_expense: Number(row.worker_expense) || 0,
        }));
        console.log("İşçi İstatistikleri:", worker_data);
        await addWorkerStats({ project_id: projectId, worker_data }).unwrap();
        notify("İşçi istatistikleri başarıyla kaydedildi.", "success");
        toggleModal();
        return;
      }
      // 3) Çoklu Bina / Daire Bilgileri
      if (formSection === 5) {
        const projectIdNum = Number(formData.project_id) || 1;

        // 1) Dairelerdeki zorunlu alan kontrolü
        for (let i = 0; i < (formData.buildings || []).length; i++) {
          const b = formData.buildings[i];
          for (let j = 0; j < b.housing_details.length; j++) {
            const hd = b.housing_details[j];
            if (
              hd.floor === "" ||
              hd.floor === undefined ||
              hd.section_no === "" ||
              hd.section_no === undefined ||
              hd.net_area === "" ||
              hd.net_area === undefined ||
              hd.gross_area === "" ||
              hd.gross_area === undefined
            ) {
              notify(
                `Lütfen tüm zorunlu alanları doldurunuz. (Bina #${
                  i + 1
                }, Daire #${j + 1})`,
                "warning"
              );
              return;
            }
          }
        }

        // 2) Dairelerin toplam brüt alanı, binanın mesken alanından az kalmasın
        for (let i = 0; i < (formData.buildings || []).length; i++) {
          const b = formData.buildings[i];
          const meskenArea = parseFloat(b.sections.mesken.area) || 0;

          // Tüm dairelerin gross_area’sını toplayalım
          let totalGross = 0;
          b.housing_details.forEach((hd) => {
            totalGross += parseFloat(hd.gross_area) || 0;
          });

          // Eğer dairelerin toplamı < mesken alanı ise uyarı verip işlemi durduruyoruz
          if (totalGross < meskenArea) {
            notify(
              `Dairelerin toplam brüt alanı (${totalGross} m²), ` +
                `binanın mesken alanı (${meskenArea} m²) değerinden AZ! ` +
                `Lütfen eksik girilen daire metrekarelerini tamamlayınız.`,
              "warning"
            );
            return;
          }
        }

        // 3) Geri kalan işlemler: addIndependentSection, createBuilding, addHousingDetails
        for (let i = 0; i < (formData.buildings || []).length; i++) {
          const b = formData.buildings[i];
          const buildingNo = b.building_no ? Number(b.building_no) : i + 1;

          const sectionPayload = {
            project_id: projectIdNum,
            sections: {
              mesken: {
                count: Number(b.sections.mesken.count) || 0,
                area: Number(b.sections.mesken.area) || 0,
              },
              ortak: {
                count: Number(b.sections.ortak.count) || 0,
                area: Number(b.sections.ortak.area) || 0,
              },
            },
          };
          await addIndependentSection(sectionPayload).unwrap();

          const buildingPayload = {
            project_id: projectIdNum,
            building_no: buildingNo,
            total_floors: Number(b.total_floors) || 1,
          };
          const cbResp = await createBuilding(buildingPayload).unwrap();

          const housingPayload = {
            project_id: projectIdNum,
            building_id: Number(cbResp.building.id),
            housing_details: b.housing_details.map((hd, j) => ({
              floor: Number(hd.floor),
              section_no: Number(hd.section_no),
              characteristic: hd.characteristic || "Mesken",
              net_area: Number(hd.net_area),
              gross_area: Number(hd.gross_area),
              is_sold: !!hd.is_sold,
              invoices: hd.invoices || [],
              product_id: hd.product_id || null,
              building_id: hd.building_id ? Number(hd.building_id) : buildingNo,
              bina_daire_label: `${i + 1}. Bina - ${j + 1}. Daire`,
            })),
          };
          await addHousingDetails(housingPayload).unwrap();
        }

        console.log("Bina ve Daire Bilgileri:", formData.buildings);
        notify("Bina ve daire bilgileri başarıyla kaydedildi.", "success");
        toggleModal();
        return;
      }

      // 4) Ürün Grup İsmi + Fatura Eşleştirmesi
      if (formSection === 6) {
        // 1) En az bir grup var mı?
        console.log("Fatura Grupları:", invoiceGroups);
        if (!invoiceGroups || invoiceGroups.length === 0) {
          notify("En az bir ürün grubu eklemelisiniz.", "warning");
          return;
        }

        // 2) Her grubun en az bir fatura seçtiğinden emin olalım
        for (const grp of invoiceGroups) {
          if (!grp.invoices || grp.invoices.length === 0) {
            notify(
              `Lütfen "${grp.group_name}" grubuna en az bir fatura ekleyiniz.`,
              "warning"
            );
            return;
          }
        }

        try {
          const projectIdNum = Number(formData.project_id) || 1;

          // 3) Tüm gruplar için createInvoiceGroup çağrısı yapıyoruz
          for (const grp of invoiceGroups) {
            const groupPayload = {
              projectId: projectIdNum,
              group_name: grp.group_name,
              group_description: grp.group_description,
              invoices: grp.invoices,
            };
            console.log("Fatura Grubu Payload:", groupPayload);
            await createInvoiceGroup(groupPayload).unwrap();
          }

          // 4) Eğer setStep6Completed fonksiyonu mevcutsa, 6. adımın tamamlandığını bildiriyoruz
          if (typeof setStep6Completed === "function") {
            setStep6Completed(true);
          }
          console.log("Fatura Grupları:", invoiceGroups);
          notify("Fatura eşleştirmesi başarıyla gerçekleştirildi.", "success");
          toggleModal();
          return;
        } catch (error) {
          notify("Fatura eşleştirmesi sırasında hata oluştu!", "error");
          console.error("createInvoiceGroup error:", error);
          return;
        }
      }

      if (formSection === 8) {
        return;
      }
      if (formData.id) {
        await updateCustomer({ ...formData });
        notify("Müşteri bilgileri başarıyla güncellendi.", "success");
      } else {
        await postCustomer({ ...formData });
        notify("Müşteri başarıyla kaydedildi.", "success");
      }
      toggleModal();
    } catch (error) {
      notifyHtml(error.response?.data || { genel: ["Bir hata oluştu."] });
    }
  };
  // -------------------------------------------------
  // Render
  // -------------------------------------------------
  return (
    <Dialog
      {...getDialogProps(formSection)}
      open={isVisible}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Proje Bilgileri Yükle</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            {/* 1) Proje Oluşturma */}
            {formSection === 1 && (
              <>
                <TextField
                  label="Proje Adı"
                  name="name"
                  value={formData.name || ""}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name || ""}
                  margin="dense"
                  required
                  fullWidth
                  sx={{ ...textFieldStyle, mb: 2 }}
                />
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <TextField
                    label="Proje Kayıt Numarası"
                    name="registration_no"
                    value={formData.registration_no || ""}
                    onChange={handleChange}
                    error={!!errors.registration_no}
                    helperText={errors.registration_no || ""}
                    margin="dense"
                    sx={{ ...textFieldStyle }}
                  />
                  <TextField
                    label="Proje Kayıt Tarihi"
                    name="registration_date"
                    value={formData.registration_date || ""}
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      const formatted = new Date(selectedDate)
                        .toISOString()
                        .split("T")[0];
                      setFormData((prev) => ({
                        ...prev,
                        registration_date: formatted,
                      }));
                    }}
                    error={!!errors.registration_date}
                    helperText={errors.registration_date || ""}
                    margin="dense"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required
                    sx={{ ...textFieldStyle }}
                  />
                </Box>
              </>
            )}

            {/* 2) İşçi İstatistikleri */}
            {formSection === 4 && (
              <>
                <Typography variant="h6" sx={{ width: "100%", mt: 3, mb: 2 }}>
                  İşçi İstatistikleri Dönemi
                </Typography>
                <Typography variant="body2" sx={{ width: "100%", mb: 2 }}>
                  Lütfen projenin başlangıç ve bitiş dönemlerini seçiniz.
                  Program otomatik olarak her aya ait satırları oluşturacaktır.
                </Typography>
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <TextField
                    label="Başlangıç Tarihi"
                    type="month"
                    value={startMonth}
                    onChange={(e) => setStartMonth(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ ...textFieldStyle }}
                  />
                  <TextField
                    label="Bitiş Tarihi"
                    type="month"
                    value={endMonth}
                    onChange={(e) => setEndMonth(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ ...textFieldStyle }}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="btnActiveColor"
                  onClick={handleCreateMonths}
                  sx={{ textTransform: "none", mt: 2 }}
                >
                  Oluştur
                </Button>
                {workers.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      mt: 2,
                      p: 2,
                      border: "1px solid #ccc",
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Dönem: <b>{item.label}</b>
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                      <TextField
                        label="İşçi Sayısı"
                        type="number"
                        value={item.worker_count || ""}
                        onChange={(e) =>
                          setWorkers((prev) => {
                            const newArr = [...prev];
                            newArr[index].worker_count = e.target.value;
                            return newArr;
                          })
                        }
                        sx={{ ...textFieldStyle }}
                      />
                      <TextField
                        label="İşçilerin Toplam Gideri"
                        type="number"
                        value={item.worker_expense || ""}
                        onChange={(e) =>
                          setWorkers((prev) => {
                            const newArr = [...prev];
                            newArr[index].worker_expense = e.target.value;
                            return newArr;
                          })
                        }
                        sx={{ ...textFieldStyle }}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setWorkers((prev) =>
                          prev.filter((_, i) => i !== index)
                        );
                      }}
                      sx={{ mt: 2 }}
                    >
                      Kaldır
                    </Button>
                  </Box>
                ))}
              </>
            )}

            {/* 3) Çoklu Bina / Daire Bilgileri */}
            {formSection === 5 && (
              <>
                <Typography variant="h6" sx={{ width: "100%", mt: 3, mb: 2 }}>
                  Lütfen projede inşa edilen bina sayısını giriniz.
                </Typography>
                <TextField
                  type="number"
                  value={formData.building_count || ""}
                  onChange={handleBuildingCountChange}
                  sx={{ width: "100%", ...textFieldStyle, mb: 2 }}
                />
                {(formData.buildings || []).map((building, bIndex) => {
                  const autoBinaNo = building.building_no
                    ? building.building_no
                    : bIndex + 1;
                  return (
                    <Box
                      key={bIndex}
                      sx={{
                        border: "1px solid #ccc",
                        p: 2,
                        mt: 3,
                        width: "100%",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ mb: 2, fontWeight: "bold" }}
                      >
                        {bIndex + 1}. Bina
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          mt: 2,
                          mb: 2,
                          fontWeight: "bold",
                          fontSize: "1.1rem",
                        }}
                      >
                        Mesken Bilgileri
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                        <TextField
                          label="Daire Sayısı (Mesken)"
                          name="mesken_count"
                          type="number"
                          value={building.sections.mesken.count}
                          onChange={(e) => handleBuildingSections(bIndex, e)}
                          sx={textFieldStyle}
                        />
                        <TextField
                          label="Toplam m² (Mesken)"
                          name="mesken_area"
                          type="number"
                          value={building.sections.mesken.area}
                          onChange={(e) => handleBuildingSections(bIndex, e)}
                          sx={textFieldStyle}
                        />
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{ mb: 2, fontWeight: "bold", fontSize: "1.1rem" }}
                      >
                        Ortak Alan Bilgileri
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                        <TextField
                          label="Ortak Alan Sayısı"
                          name="ortak_count"
                          type="number"
                          value={building.sections.ortak.count}
                          onChange={(e) => handleBuildingSections(bIndex, e)}
                          sx={textFieldStyle}
                        />
                        <TextField
                          label="Toplam m² (Ortak)"
                          name="ortak_area"
                          type="number"
                          value={building.sections.ortak.area}
                          onChange={(e) => handleBuildingSections(bIndex, e)}
                          sx={textFieldStyle}
                        />
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{ mb: 2, fontWeight: "bold", fontSize: "1.1rem" }}
                      >
                        Bina Bilgileri
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                        <TextField
                          label="Bina Numarası"
                          type="number"
                          value={autoBinaNo}
                          onChange={(e) =>
                            handleBuildingInfo(
                              bIndex,
                              "building_no",
                              e.target.value
                            )
                          }
                          sx={textFieldStyle}
                        />
                        <TextField
                          label="Toplam Kat Sayısı"
                          type="number"
                          value={building.total_floors}
                          onChange={(e) =>
                            handleBuildingInfo(
                              bIndex,
                              "total_floors",
                              e.target.value
                            )
                          }
                          sx={textFieldStyle}
                        />
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{ mb: 2, fontWeight: "bold", fontSize: "1.1rem" }}
                      >
                        Daire (Bağımsız Bölüm) Bilgileri
                      </Typography>
                      {building.housing_details.map((hd, dIndex) => (
                        <Box
                          key={dIndex}
                          sx={{
                            width: "100%",
                            mt: 2,
                            p: 2,
                            border: "1px solid #999",
                            borderRadius: 1,
                            mb: 3,
                          }}
                        >
                          <Typography variant="subtitle2" sx={{ mb: 2 }}>
                            {bIndex + 1}. Bina - {dIndex + 1}. Daire
                          </Typography>
                          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                            <TextField
                              label="Kat (floor)"
                              value={hd.floor}
                              onChange={(e) =>
                                handleHousingDetailChange(
                                  bIndex,
                                  dIndex,
                                  "floor",
                                  e.target.value
                                )
                              }
                              sx={textFieldStyle}
                            />
                            <TextField
                              label="Daire/Section No"
                              value={hd.section_no || dIndex + 1}
                              onChange={(e) =>
                                handleHousingDetailChange(
                                  bIndex,
                                  dIndex,
                                  "section_no",
                                  e.target.value
                                )
                              }
                              sx={textFieldStyle}
                            />
                            <TextField
                              label="Özellik (characteristic)"
                              value={hd.characteristic}
                              onChange={(e) =>
                                handleHousingDetailChange(
                                  bIndex,
                                  dIndex,
                                  "characteristic",
                                  e.target.value
                                )
                              }
                              sx={textFieldStyle}
                            />
                          </Box>
                          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                            <TextField
                              label="Net Alan (m²)"
                              type="number"
                              value={hd.net_area}
                              onChange={(e) =>
                                handleHousingDetailChange(
                                  bIndex,
                                  dIndex,
                                  "net_area",
                                  e.target.value
                                )
                              }
                              sx={textFieldStyle}
                            />
                            <TextField
                              label="Brüt Alan (m²)"
                              type="number"
                              value={hd.gross_area}
                              onChange={(e) =>
                                handleHousingDetailChange(
                                  bIndex,
                                  dIndex,
                                  "gross_area",
                                  e.target.value
                                )
                              }
                              sx={textFieldStyle}
                            />
                          </Box>
                          <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Satıldı mı?
                          </Typography>
                          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                            <FormControl>
                              <RadioGroup
                                row
                                value={hd.is_sold ? "true" : "false"}
                                onChange={(e) =>
                                  handleHousingDetailChange(
                                    bIndex,
                                    dIndex,
                                    "is_sold",
                                    e.target.value === "true"
                                  )
                                }
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio color="primary" />}
                                  label="Evet"
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio color="primary" />}
                                  label="Hayır"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                          {hd.is_sold && (
                            <Box sx={{ ml: 2, mb: 2 }}>
                              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                Fatura Bilgileri
                              </Typography>
                              {hd.invoices.map((inv, invIdx) => (
                                <Box
                                  key={invIdx}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    mt: 1,
                                  }}
                                >
                                  <Autocomplete
                                    options={invoiceData || []}
                                    getOptionLabel={(option) =>
                                      `${option.invoice_no} - ${
                                        option.customer_vkn_tckn
                                      } - ${
                                        option.product_lines?.[0]
                                          ?.product_name || ""
                                      }`
                                    }
                                    // Değer atama
                                    value={
                                      invoiceData?.find(
                                        (item) =>
                                          item.invoice_no === inv.invoice_no
                                      ) || null
                                    }
                                    // Seçim değiştiğinde
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        handleInvoiceChange(
                                          bIndex,
                                          dIndex,
                                          invIdx,
                                          newValue.invoice_no
                                        );
                                      } else {
                                        handleInvoiceChange(
                                          bIndex,
                                          dIndex,
                                          invIdx,
                                          ""
                                        );
                                      }
                                    }}
                                    disabled={invoiceLoading}
                                    // Asıl kritik kısım: filterOptions
                                    filterOptions={(options, state) => {
                                      const searchTerm =
                                        state.inputValue.toLowerCase();

                                      return options.filter((option) => {
                                        // Ekrana yazdırdığımız label’ın tamamını küçük harfe çeviriyoruz:
                                        const fullLabel = `${
                                          option.invoice_no
                                        } - ${option.customer_vkn_tckn} - ${
                                          option.product_lines?.[0]
                                            ?.product_name || ""
                                        }`.toLowerCase();

                                        // İçeriyorsa eşleşme var
                                        return fullLabel.includes(searchTerm);
                                      });
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Fatura Seçiniz"
                                        fullWidth
                                        sx={{ minWidth: 690 }}
                                      />
                                    )}
                                  />
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      removeInvoice(bIndex, dIndex, invIdx)
                                    }
                                  >
                                    SİL
                                  </Button>
                                </Box>
                              ))}
                              <Button
                                variant="outlined"
                                onClick={() => addInvoice(bIndex, dIndex)}
                                color="btnActiveColor"
                                sx={{ mt: 1 }}
                              >
                                + Fatura Ekle
                              </Button>
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  );
                })}
              </>
            )}

            {/* 4) Ürün Grup İsmi + Fatura Eşleştirmesi */}
            {formSection === 6 && (
              <Box sx={{ width: "100%" }}>
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                  Ürün Grupları (Beton / Demir + Yeni Grup)
                </Typography>

                {invoiceGroups.map((grp, idx) => {
                  // "Diğer" grubunu tespit edelim:
                  const isDigerGroup = grp.group_name === "Diğer";

                  return (
                    <Box
                      key={idx}
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: 1,
                        p: 2,
                        mb: 2,
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        {grp.group_name || `Yeni Grup #${idx + 1}`}
                      </Typography>

                      {/* Sadece Diğer grubunda alt başlık göster */}
                      {isDigerGroup && (
                        <Typography variant="subtitle2" sx={{ mb: 2 }}>
                          Bu projeye ait Yüklenim Listesinde konu edilmesini
                          istediğiniz bütün faturaları bu grup altında seçiniz.
                        </Typography>
                      )}

                      {/* Grup Adı (kullanıcı değiştiremesin => disabled) */}
                      <TextField
                        label="Grup Adı"
                        value={grp.group_name}
                        disabled
                        fullWidth
                        sx={{ mb: 2 }}
                      />

                      {/* Grup Açıklaması (kullanıcı değiştiremesin => disabled) */}
                      <TextField
                        label="Grup Açıklaması"
                        value={
                          isDigerGroup
                            ? "Diğer Ürün Grupları"
                            : grp.group_description || ""
                        }
                        disabled
                        fullWidth
                        sx={{ mb: 2 }}
                      />

                      {/* Fatura Seçimi */}
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id={`invoice-select-label-${idx}`}>
                          Faturalar
                        </InputLabel>
                        <Select
                          labelId={`invoice-select-label-${idx}`}
                          multiple
                          value={grp.invoices}
                          onChange={(e) =>
                            handleGroupChange(idx, "invoices", e.target.value)
                          }
                          input={<OutlinedInput label="Faturalar" />}
                          renderValue={(selected) =>
                            selected
                              .map(
                                (sel) =>
                                  `${sel.invoice_no} (${sel.product_name}, ${sel.quantity} ${sel.unit})`
                              )
                              .join(", ")
                          }
                        >
                          {projectInvoiceLoading && (
                            <MenuItem disabled>Yükleniyor...</MenuItem>
                          )}
                          {projectInvoiceError && (
                            <MenuItem disabled>Hata oluştu.</MenuItem>
                          )}

                          {!projectInvoiceLoading &&
                          Array.isArray(projectInvoiceData) &&
                          projectInvoiceData.length > 0 ? (
                            projectInvoiceData.map((inv) => (
                              <MenuItem
                                key={`${inv.invoice_no}-${inv.product_name}-${inv.quantity}`}
                                value={inv}
                              >
                                <Checkbox
                                  checked={grp.invoices.some(
                                    (selInv) =>
                                      selInv.invoice_no === inv.invoice_no &&
                                      selInv.product_name ===
                                        inv.product_name &&
                                      selInv.quantity === inv.quantity
                                  )}
                                />
                                <ListItemText
                                  primary={`Fatura No: ${inv.invoice_no} | Ürün: ${inv.product_name}`}
                                  secondary={`Tarih: ${inv.invoice_date} | Fiyat: ${inv.equivalent_unit_price} TL | KDV: ${inv.equivalent_vat_amount} TL | Miktar: ${inv.quantity} ${inv.unit}`}
                                />
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>Fatura bulunamadı</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  );
                })}

                {/* + Grup Ekle butonu (en fazla 3 grup = Beton, Demir, Diğer) */}
                {invoiceGroups.length < 3 && (
                  <Button
                    variant="outlined"
                    onClick={handleAddGroup}
                    color="btnActiveColor"
                    sx={{ mt: 2 }}
                  >
                    + Grup Ekle
                  </Button>
                )}
              </Box>
            )}

            {/* 8) Proje Yapı Tpini*/}
            {formSection === 8 && (
              <Box sx={{ width: "100%" }}>
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                  Proje Yapı TipiniHesaplama
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Lütfen "Karkas" veya "Yığma" seçeneğini belirleyip, hesapla
                  butonuna basınız.
                </Typography>
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                  <RadioGroup
                    row
                    value={constructionType}
                    onChange={(e) => setConstructionType(e.target.value)}
                  >
                    <FormControlLabel
                      value="karkas"
                      control={<Radio color="primary" />}
                      label="Karkas"
                    />
                    <FormControlLabel
                      value="yigma"
                      control={<Radio color="primary" />}
                      label="Yığma"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                <Button
                  variant="contained"
                  color="btnActiveColor"
                  onClick={handleCalculateMaterialUsage}
                  disabled={calculateLoading}
                >
                  Hesapla
                </Button>
                {calculateLoading && (
                  <Typography sx={{ mt: 2 }}>Hesaplanıyor...</Typography>
                )}
                {materialUsageData?.calculated_materials &&
                  materialUsageData.calculated_materials.length > 0 && (
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Toplam Alan: {materialUsageData.total_area} m²
                      </Typography>
                      <TableComponent
                        data={materialUsageData.calculated_materials}
                        tableColumns={materialUsageColumns}
                        showFatura={true}
                      />
                    </Box>
                  )}
              </Box>
            )}
            {/* 11) Karşıt İnceleme Özet Tablosu */}
            {formSection === 11 && (
              <Box sx={{ width: "100%" }}>
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                  Karşıt İnceleme Özet Tablosu
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Başlangıç ve Bitiş Tarihlerini seçip "Raporu Getir" butonuna
                  basınız.
                </Typography>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <TextField
                    label="Başlangıç Tarihi"
                    type="date"
                    value={karsitStartDate}
                    onChange={(e) => setKarsitStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Bitiş Tarihi"
                    type="date"
                    value={karsitEndDate}
                    onChange={(e) => setKarsitEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="btnActiveColor"
                  onClick={handleGenerateKarsitIncelemeSummary}
                  disabled={karsitSummaryLoading}
                >
                  Raporu Getir
                </Button>
                {karsitSummaryLoading && (
                  <Typography sx={{ mt: 2 }}>Rapor getiriliyor...</Typography>
                )}
                {karsitSummaryData && karsitSummaryData.total_kdv_per_period ? (
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                      Satıcı Pivot Tablosu
                    </Typography>
                    <TableComponent
                      data={karsitSummaryData.satici_pivot_table}
                      tableColumns={dynamicSaticiColumns}
                      showFatura={true}
                    />
                    <Grid container spacing={2} mt={2}>
                      <Grid item>
                        <ExportData
                          url={`api/indirimli_oran/karsit-inceleme-summary/${projectId}/${
                            tckn || tckn || ""
                          }?start_date=${karsitStartDate}&end_date=${karsitEndDate}&excel=True`}
                          dosyaAdi={`karsit_inceleme_ozet_${karsitStartDate}_${karsitEndDate}.xlsx`}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      Dönemlere Ait Toplam KDV
                    </Typography>
                    {/* Dikey olarak gelen total_kdv_per_period verisini, tek satırlık yatay tabloya dönüştürüyoruz */}
                    <TableComponent
                      data={[karsitSummaryData.total_kdv_per_period]}
                      tableColumns={Object.keys(
                        karsitSummaryData.total_kdv_per_period
                      ).map((dönem) => ({
                        Header: dönem,
                        accessor: dönem,
                      }))}
                      showFatura={true}
                    />
                  </Box>
                ) : (
                  <Typography sx={{ mt: 2 }}>
                    Herhangi bir KDV verisi bulunamadı.
                  </Typography>
                )}
              </Box>
            )}

            {stepsWithPost.includes(formSection) && (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="btnActiveColor"
                sx={{ mt: 3 }}
              >
                {formData.id ? "Güncelle" : "Kaydet"}
              </Button>
            )}
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal} color="btnColor">
          İptal
        </Button>
      </DialogActions>
      <Dialog
        open={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
      >
        <DialogTitle>Uyarı</DialogTitle>
        <DialogContent>{modalMessage}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccessModalVisible(false);
              toggleModal();
            }}
            color="primary"
          >
            Tamam
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default IndirimliKayitForm;
